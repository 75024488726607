<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="420"
    scrollable
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addDriver)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="font-weight-bold blue-grey--text ml-1"
              >Set Up New Driver</span
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close" color="prmary">
              <i class="material-icons">close</i>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="driver.name"
                    class="ft font-weight-medium font-size-md"
                    label="Driver's Full Name"
                    :error-messages="errors[0]"
                    hint="Name of the driver"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|numeric"
                >
                  <v-text-field
                    outlined
                    v-model="driver.phoneNumber"
                    class="ft font-weight-medium font-size-md"
                    label="Contact Number"
                    :error-messages="errors[0]"
                    hint="Contact of driver"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|numeric"
                >
                  <v-autocomplete
                    outlined
                    class="ft font-weight-medium"
                    label="Assign Car To This Driver"
                    :error-messages="errors[0]"
                    :items="cars"
                    v-model="driver.carId"
                    item-value="id"
                    item-text="registrationNumber"
                    :loading="isCarListLoading"
                    @focus="onFocus"
                  >
                    <template #item="{item}">
                      <div class="d-flex flex-row flex-grow-1">
                        <div class="d-flex flex-row">
                          <v-avatar
                            size="40"
                            class="white--text"
                            color="primary"
                            >{{ item.registrationNumber.charAt(0) }}</v-avatar
                          >
                        </div>
                        <div class="d-flex flex-row flex-grow-1 mt-2 ml-3">
                          <v-chip label small>
                            <span
                              class="ft font-weight-bold text-uppercase text-lg"
                              >{{ item.registrationNumber }}</span
                            >
                          </v-chip>
                          <v-chip
                            v-if="item.model || item.brand"
                            label
                            small
                            class="ml-2"
                          >
                            <span
                              class="ft font-weight-bold text-uppercase text-lg"
                              >{{ item.model ? item.model : item.brand }}</span
                            >
                          </v-chip>
                        </div>
                      </div>
                    </template>
                    <template #no-data>
                      <div class="d-flex flex-row flex-grow-1 justify-center">
                        <span
                          class="ft mx-2 font-size-md font-weight-medium pa-2"
                          >No Vehicle Added Yet</span
                        ><v-btn
                          @click.stop="open('newCar')"
                          text
                          color="primary"
                        >
                          <span class="font-size-sm ft text-capitalize"
                            >Add New Vehicle</span
                          >
                          <i class="material-icons">add</i>
                        </v-btn>
                      </div>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2 ft text-capitalize" text @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="isLoading"
              :disabled="isLoading"
              type="submit"
              class="ft white--text text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
    <AddNewCar
      :state="dialog('newCar')"
      @actions="closeNewCar"
      @car="addNewCar"
    />
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import WidgetMixins from "../../../../mixins/WidgetMixins";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "AddDriverAndAssignCar",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
    AddNewCar: () => import(`../../transport/dialogs/AddNewCar`)
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogState: {
      newCar: false
    },
    driver: {
      name: "",
      phoneNumber: "",
      carId: ""
    }
  }),
  mixins: [WidgetMixins],
  computed: {
    ...mapGetters({
      isLoading: "getIsLoading",
      isSubLoading: "getIsSubLoading",
      isCarListLoading: "getIsListLoading",
      cars: "transport/getAllCars"
    })
  },
  watch: {
    isSubLoading(value) {
      if (!value) {
        this.dialogState["newCar"] = value;
        this.$store.dispatch("transport/listOfCars");
      }
    },
    isLoading(value) {
      if (!value) {
        //this.driver = { name: null, phoneNumber: null, cardId: null };
      }
    }
  },
  methods: {
    closeNewCar(payload) {
      this.dialogState[payload] = false;
    },
    close() {
      this.$emit("actions", "add");
    },
    onFocus() {
      this.$store.dispatch("transport/listOfCars");
    },
    addNewCar(payload) {
      this.$store.dispatch("transport/addCar", payload);
    },
    addDriver() {
      this.$emit("driver", this.driver);
    }
  }
};
</script>
