var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","max-width":"420","scrollable":"","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addDriver)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"font-weight-bold blue-grey--text ml-1"},[_vm._v("Set Up New Driver")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"prmary"},on:{"click":_vm.close}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])],1),_c('v-card-text',[_c('v-col',[_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"outlined":"","autofocus":"","label":"Driver's Full Name","error-messages":errors[0],"hint":"Name of the driver"},model:{value:(_vm.driver.name),callback:function ($$v) {_vm.$set(_vm.driver, "name", $$v)},expression:"driver.name"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"outlined":"","label":"Contact Number","error-messages":errors[0],"hint":"Contact of driver"},model:{value:(_vm.driver.phoneNumber),callback:function ($$v) {_vm.$set(_vm.driver, "phoneNumber", $$v)},expression:"driver.phoneNumber"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium",attrs:{"outlined":"","label":"Assign Car To This Driver","error-messages":errors[0],"items":_vm.cars,"item-value":"id","item-text":"registrationNumber","loading":_vm.isCarListLoading},on:{"focus":_vm.onFocus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-avatar',{staticClass:"white--text",attrs:{"size":"40","color":"primary"}},[_vm._v(_vm._s(item.registrationNumber.charAt(0)))])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-2 ml-3"},[_c('v-chip',{attrs:{"label":"","small":""}},[_c('span',{staticClass:"ft font-weight-bold text-uppercase text-lg"},[_vm._v(_vm._s(item.registrationNumber))])]),(item.model || item.brand)?_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","small":""}},[_c('span',{staticClass:"ft font-weight-bold text-uppercase text-lg"},[_vm._v(_vm._s(item.model ? item.model : item.brand))])]):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('span',{staticClass:"ft mx-2 font-size-md font-weight-medium pa-2"},[_vm._v("No Vehicle Added Yet")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('newCar')}}},[_c('span',{staticClass:"font-size-sm ft text-capitalize"},[_vm._v("Add New Vehicle")]),_c('i',{staticClass:"material-icons"},[_vm._v("add")])])],1)]},proxy:true}],null,true),model:{value:(_vm.driver.carId),callback:function ($$v) {_vm.$set(_vm.driver, "carId", $$v)},expression:"driver.carId"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ft text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft white--text text-capitalize",attrs:{"color":"primary","loading":_vm.isLoading,"disabled":_vm.isLoading,"type":"submit"}},[_vm._v(" Save ")])],1)],1)],1)]}}])}),_c('AddNewCar',{attrs:{"state":_vm.dialog('newCar')},on:{"actions":_vm.closeNewCar,"car":_vm.addNewCar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }